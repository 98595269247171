import React from "react";
import PropTypes from "prop-types";
import { Container } from "theme-ui";
import SectionTitle from "./Section.Title";

const SECTION_VARIANT = "section";
const SIDE_SECTION_VARIANT = "section-aside";

const Section = ({ aside, posts, isFeatured, children, ...props }) => {
  const sectionVariant = aside ? SIDE_SECTION_VARIANT : SECTION_VARIANT;
  const totalPosts = posts ? posts.length : 0;
  return (
    <Container variant={sectionVariant}>
      <SectionTitle
        isFeatured={isFeatured}
        totalPosts={totalPosts}
        variant={sectionVariant}
        {...props}
        children={children}
      />
      {children}
    </Container>
  );
};

export default Section;

Section.propTypes = {
  title: PropTypes.string,
  titleLink: PropTypes.string,
  omitTitle: PropTypes.bool,
  aside: PropTypes.bool
};
